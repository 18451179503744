// src/pages/Dashboard.jsx

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  // التحقق من حالة الجلسة عند تحميل الصفحة
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/login"); // إذا لم تكن الجلسة موجودة، يتم إعادة توجيه المستخدم إلى صفحة الـ Login
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn"); // حذف الجلسة عند الخروج
    navigate("/"); // إعادة توجيه المستخدم إلى الصفحة الرئيسية (Home)
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome to the Dashboard</h1>
      <button onClick={handleLogout}>Log Out</button>
    </div>
  );
};

export default Dashboard;

const style = `
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #080808;
    font-family: Arial, sans-serif;
  }

  .dashboard-container h1 {
    font-size: 2rem;
    color: #white;
    margin-bottom: 20px;
  }

  .dashboard-container button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #f80c0c; /* اللون الأحمر البرتقالي */
    border: none;
    border-radius: 5px;
    color: #white;
    cursor: pointer;
  }

  .dashboard-container button:hover {
    background-color: #f80c0c; /* التفاعل مع الزر عند المرور عليه */
  }
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = style;
document.head.appendChild(styleTag);
