import React from 'react';
import './assets/erorr.css';

const Home = () => {
    return (
        <section className="error-page">
      <div className="error-content">
        <h1>404</h1>
        <p>Page Not Found</p>
        <div className="btn-group">
          <a href="https://github.com/RWELYY/" className="btn">Github</a>
          <a href="/" className="btn">Home</a>
        </div>
      </div>
    </section>
    );
  };
  
  export default Home;
  
