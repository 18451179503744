import React from 'react';
import logo from "./assets/2.gif";
import './assets/home.css';
import About from './About';
import Contact from './Contact';


const Home = () => {
  return (
    <div>
      <section className="home" id="home">
      <div className="home-content">
        <h1>Hi I`m <span>Rwélyy</span></h1>
        <h3>I`m a <span>Full-Stack Developer</span></h3>
        <p>I am a motivated and self-taught Frontend Web Developer with a passion for creating dynamic, responsive, and user-friendly websites. With a solid foundation in modern web technologies and a keen eye for design, I aim to provide seamless and engaging user experiences. Currently, I am pursuing my degree in Commerce while actively developing my skills and portfolio in web development.</p>
        
        <div className="social-icons">
          <a href="https://github.com/RWELYY/"><i className="bx bxl-github"></i></a>
          <a href="https://instagram.com/rwelyy/"><i className="bx bxl-instagram-alt"></i></a>
          <a href="https://discord.com/channels/@me/470306150592675841"><i className="bx bxl-discord"></i></a> 
        </div>

        <div className="button-group">
          <a href="https://github.com/RWELYY/" className="button">Github</a>
          <a href="#contact" className="button">Contact</a>
        </div>
      </div>
      <div className="home-img">
        <img src={logo} alt="yassin" />
      </div>
    </section>
    <About/>
    <Contact/>
    </div>
  );
};

export default Home;
