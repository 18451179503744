import React from 'react';
import './assets/navbar.css'

const Navbar = () => {
  return (
    <header className="header">
      <a href="https://3omar.icu" className="logo"> Omar <span>Rwélyy</span></a>
      <i className="bx bx-menu" id="menu-icon"></i>
      <nav className="navbar">
        <a href="https://3omar.icu">Home</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </nav>
    </header>
  );
};



export default Navbar;
