import React, { useState } from "react";
import emailjs from "emailjs-com";
import './assets/contact.css'

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_qrqtnh6",
        "template_1j8c5i7",
        formData,
        "j17Td_tm_l6gUEIPE"
      )
      .then((response) => {
        setStatusMessage("تم إرسال الرسالة بنجاح!");
      })
      .catch((err) => {
        setStatusMessage("فشل إرسال الرسالة!");
        console.error("Error sending email:", err);
      });

    setFormData({ name: "", email: "", phone: "", subject: "", message: "" });

    setTimeout(() => {
      setStatusMessage("");
    }, 60000);
  };

  return (
    <section className="contact" id="contact">
      <h2 className="heading">Contact <span>me</span></h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="input-box">
            <input 
              type="text" 
              name="name"
              placeholder="Full Name" 
              value={formData.name}
              onChange={handleChange}
              required 
            />
            <input 
              type="email" 
              name="email"
              placeholder="Email" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
          </div>
          <div className="input-box">
            <input 
              type="number" 
              name="phone"
              placeholder="Phone Number" 
              value={formData.phone}
              onChange={handleChange}
              required 
            />
            <input 
              type="text" 
              name="subject"
              placeholder="Subject" 
              value={formData.subject}
              onChange={handleChange}
              required 
            />
          </div>
        </div>
        <div className="input-group-2">
          <textarea 
            name="message"
            cols="30" 
            rows="10" 
            placeholder="Your Message" 
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <input type="submit" value="Send Message" className="button" />
        </div>
      </form>
      {statusMessage && (
        <p className="status-message">
          {statusMessage}
        </p>
      )}

      <style jsx>{`
        .status-message {
          font-size: 1.5rem; /* تكبير حجم الخط */
          font-weight: bold;
          text-align: center;
          color: white;
          margin-top: 1rem;
          padding: 0.5rem;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.5); /* لون خلفية لتكون واضحة */
        }
      `}</style>
    </section>
  );
}
