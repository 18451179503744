import Home from "./pages/Home";
import Footer from "./pages/Footer";
import Navbar from "./pages/Navbar";
import Error from "./pages/Error";
import Login from "./login/login";
import Dashboard from "./login/Dashboard";
import "./style.css";
import "./responsive.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/login" element={<Login />} /> 
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="*" element={<Error />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
