import React from 'react';
import logo from "./assets/2.gif";
import './assets/about.css'

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-img">
        <img src={logo} alt="yassin" />
      </div>
      <div className="about-content">
        <h2>About <span>Me</span></h2>
        <p>My Name is Omar</p>
        <p>From Egypt</p>
        <p>Full-Stack Web Developer</p>
        <a href="#contact" className="btn">Contact</a>
      </div>
    </section>
  );
};

export default About;
